import { Box, Typography } from "@mui/material";
import { GameCard } from "cob-components-game-card";
import { VideoGameProps, Platform, Genre } from "cob-components-game-card/dist/types";

const mockPlatforms: Platform[] = ["PC", "PlayStation", "Xbox"];
const mockGenres: Genre[] = ["Action", "Adventure"];
const mockData: VideoGameProps[] = [
    {
        id: "1",
        name: "Super Mario Bros.",
        platform: mockPlatforms,
        releaseDate: "1985",
        genre: mockGenres,
        publisher: "Nintendo",
        shortDescription: "A classic platformer game",
        image: {
            alt: 'image',
            height: "80",
            width: "80",
            url: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
        }
    },
    // Add more mock data as needed
];
export const VideoGamesPage = () => {


    // fetch(
    //     "https://api.igdb.com/v4/games",
    //     { method: 'POST',
    //       headers: {
    //         'Accept': 'application/json',
    //         'Client-ID': 't4orzkovxwsxp3yo43fxpnqnshp04n',
    //         'Authorization': 'Bearer 73v4ogdal8mpcyhjv7rkkxs7ano5wb',
    //       },
    //       body: "fields age_ratings,aggregated_rating,aggregated_rating_count,alternative_names,artworks,bundles,category,checksum,collection,collections,cover,created_at,dlcs,expanded_games,expansions,external_games,first_release_date,follows,forks,franchise,franchises,game_engines,game_localizations,game_modes,genres,hypes,involved_companies,keywords,language_supports,multiplayer_modes,name,parent_game,platforms,player_perspectives,ports,rating,rating_count,release_dates,remakes,remasters,screenshots,similar_games,slug,standalone_expansions,status,storyline,summary,tags,themes,total_rating,total_rating_count,updated_at,url,version_parent,version_title,videos,websites;"
    //   })
    //     .then(response => {
    //         console.log(response.json());
    //     })
    //     .catch(err => {
    //         console.error(err);
    //     });


    return (
        <Box>
            <Typography>VideoGames</Typography>

            <GameCard
                name={mockData[0].name} genre={[]} id={""} image={{
                    alt: "",
                    height: "",
                    url: "",
                    width: ""
                }} platform={mockData[0].platform} publisher={mockData[0].publisher} releaseDate={mockData[0].releaseDate}
                shortDescription={mockData[0].shortDescription}>
            </GameCard>

        </Box>
    );
};
