import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Suspense, lazy, memo } from "react";
const YouTube = lazy(() => import('./YouTube'));

const elderRingVideoIds = ['LuulJZP4MRQ', 'hQWKb5tAN_U', /*'XqE1PZlR7os',*/ 'eCHG3R17rhI', 'UuSt0--03gw', 'lFNu6crvHl4', 'NW7b8EP2GKo', 'o0UGagBzt-k', 'jeOw5pZCFis'];

const VideosList = memo(function VideosList() {
    const theme = useTheme();
    return (
        <Paper elevation={5} >
            <Box sx={{p: '2rem'}} >
                <Typography variant="h4">Elder Ring Videos</Typography>
                <Typography variant="body1">Videos on how to defeat some of the bosses on Elden Ring</Typography>
                <Typography variant="body1">Click on the videos to watch them!</Typography>
            </Box>
            <Box sx={
            {
                alignItems: 'center',
                display: 'flex',
                flexDirection: "row",
                flexWrap: 'wrap',
                gap: '1rem',
                justifyContent: 'center',
                padding: '20px',
                [theme.breakpoints.down("sm")]: {
                    flexDirection: 'column',
                },
            }
        }>

                {elderRingVideoIds.map((id) => (
                    <Suspense key={id} fallback={<></>}>

                        <YouTube videoId={id} />
                    </Suspense>
                ))}
            </Box>
        </Paper>
    );
});

export default VideosList;
