import { create } from 'zustand';

type State = {
  activeMenu: string;
};

type Action = {
  updateActiveMenu: (activeMenu: State['activeMenu']) => void;
};

// Create your store, which includes both state and (optionally) actions
const useABStore = create<State & Action>((set) => ({
  activeMenu: "",
  updateActiveMenu: (menu) => set(() => ({ activeMenu: menu })),
}));

export default useABStore;

