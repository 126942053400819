import { Container } from '@mui/material';
import VideosList from '../components/Content/VideosList';
import { Suspense } from 'react';

export const HowToPage = () => {
    return (
        <Container maxWidth="xl" sx={{ mt: "5.5rem", mb: "4rem" }} >
            <Suspense fallback={<div>Loading...</div>}>
                <VideosList />
            </Suspense>
        </Container>
    );
};
