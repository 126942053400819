import { Box, Typography } from "@mui/material";

type ErrorProps = {
    error: Error | unknown;
    resetErrorBoundary?: () => void;
};
function ErrorFallback({ error }: ErrorProps) {
    let message: string;
    if (error instanceof Error) {
        message = error.message;
    } else if (error && typeof error === 'object' && 'message' in error) {
        message = String(error.message);
    } else if (typeof error === 'string') {
        message = error;
    } else {
        message = 'Something went wrong';
    }

    return (
        <Box role="alert">
            <Typography>Something went wrong:</Typography>
            <Typography color="error">{message}</Typography>
            {/* <Button onClick={resetErrorBoundary}>Try again</Button> */}
        </Box>
    );
};

export default ErrorFallback;
