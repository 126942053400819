import { useState, useEffect } from "react";
import { ORIENTATION_LANDSCAPE } from "../types";


export const useHorizontalScreen = () => {
    const [isHorizontal, setIsHorizontal] = useState(window.matchMedia(ORIENTATION_LANDSCAPE).matches);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(orientation: landscape)');

        const handleOrientationChange = () => {
            setIsHorizontal(mediaQuery.matches);
        };

        mediaQuery.addEventListener('change', handleOrientationChange);

        return () => {
            mediaQuery.removeEventListener('change', handleOrientationChange);

        };
    }, []);

    return {
        isHorizontal
    };
}

