import { Header } from './components/Header';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
// import { Footer } from './components/Footer';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorBoundary/ErrorFallback';

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box sx={{ width: "100%", display: "flex", height: "100%", flexFlow: "column" }}>
        <Header />
        <Outlet />
        {/* <Footer /> */}
      </Box>
    </ErrorBoundary>
  );
}

export default App;
