
export const youTubeContainerSkeleton = {
    height: 300,
    width: 500
};

export const youTubeContainerSkeletonXs = {
    height: 200,
    width: 250
};

export const ORIENTATION_LANDSCAPE = "(orientation: landscape)";
