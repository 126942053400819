import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { createTheme, ThemeProvider } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorBoundary/ErrorFallback';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes.tsx';


createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ThemeProvider theme={createTheme()}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
      >
        <RouterProvider router={routes} />
      </ErrorBoundary>
    </ThemeProvider>
  </StrictMode>,
);
